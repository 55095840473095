import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "billing-details" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = {
  key: 0,
  class: "col-md-6"
}

import { reactive, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'billing-details',
  props: {
    billing: {}
  },
  emits: ['update:billing'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const localBilling = reactive({
    type: props.billing.type || '',
    gstNumber: props.billing.gstNumber || ''
});

watch(localBilling, (newVal) => {
    emit('update:billing', { ...newVal });
}, { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "Billing Type*", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localBilling.type) = $event)),
          required: ""
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("option", { value: "" }, "Select Billing Type", -1),
          _createElementVNode("option", { value: "estimate" }, "Estimate", -1),
          _createElementVNode("option", { value: "gst" }, "GST", -1)
        ]), 512), [
          [_vModelSelect, localBilling.type]
        ])
      ]),
      (localBilling.type === 'gst')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "GST Number", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localBilling.gstNumber) = $event)),
              pattern: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
              placeholder: "Enter GST Number"
            }, null, 512), [
              [_vModelText, localBilling.gstNumber]
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})