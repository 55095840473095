<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { fetchGetApi, fetchPatchApi } from '@/util/api';
const baseUrl = process.env.VUE_APP_BASE_URL;

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/operation/get/all/exchanges').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data');
        }
    });
});

function downloadExcel() {
    const worksheet = XLSX.utils.json_to_sheet(allData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exchanges");
    XLSX.writeFile(workbook, 'exchanges.xlsx');
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();
    const data = Array.isArray(allData.value) ? allData.value : [];
    const filteredData = data.filter(item =>
        item.employeeName.toLowerCase().includes(query) ||
        item.toSiteName.toLowerCase().includes(query)
    );
    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    const data = Array.isArray(allData.value) ? allData.value : [];
    return data.filter(item =>
        item.employeeName.toLowerCase().includes(query) ||
        item.toSiteName.toLowerCase().includes(query)
    ).slice((currentPage.value - 1) * elementsPerPage.value, currentPage.value * elementsPerPage.value);
});

async function approveExchange(exchangeId, currentStatus) {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to ${currentStatus ? 'revoke' : 'approve'} this exchange?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: currentStatus ? 'Revoke' : 'Approve',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPatchApi(`api/operation/exchange/approve`, {
                exchangeId: exchangeId,
                isApproved: !currentStatus
            });

            if (response && response.data) {
                // Update the local data
                const index = allData.value.findIndex(item => item._id === exchangeId);
                if (index !== -1) {
                    allData.value[index].isApproved = !currentStatus;
                }
                
                toast.success(`Exchange ${!currentStatus ? 'approved' : 'revoked'} successfully`);
            }
        }
    } catch (error) {
        toast.error('Failed to update exchange status');
        console.error(error);
    }
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Exchange" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer" @submit.prevent>
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search" class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm" v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Employee Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">From Site</th>
                                    <th class="sorting_asc text-nowrap" scope="col">From Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">From Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Working Hour</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Salary</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Site</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Employee Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Working Hour</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Salary</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="13" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in filteredData" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.employeeName }}</td>
                                    <td>{{ item.fromSiteName }}</td>
                                    <td>{{ item.fromDepartmentName }}</td>
                                    <td>{{ item.fromDesignationName }}</td>
                                    <td>{{ item.workingHour }}</td>
                                    <td>{{ item.salary }}</td>
                                    <td>{{ item.toSiteName }}</td>
                                    <td>{{ item.toEmployeeName }}</td>
                                    <td>{{ item.toDepartmentName }}</td>
                                    <td>{{ item.toDesignationName }}</td>
                                    <td>{{ item.toWorkingHour }}</td>
                                    <td>{{ item.toSalary }}</td>
                                    <td>
                                        <button 
                                            class="btn btn-sm"
                                            :class="item.isApproved ? 'btn-danger' : 'btn-success'"
                                            @click="approveExchange(item._id, item.isApproved)"
                                        >
                                            {{ item.isApproved ? 'Revoke' : 'Approve' }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>