<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";

const allData = ref([]);
const elementsPerPage = ref(10);
const currentPage = ref(1);
const totalPages = ref(1);
const selectedEmployee = ref('');
const showPhotoModal = ref(false);
const selectedPhoto = ref('');
const uniqueEmployees = computed(() => {
    const employees = new Set();
    allData.value.forEach(item => {
        employees.add(item.empId.employeeName);
    });
    return Array.from(employees);
});

// Computed property for filtered data
const filteredData = computed(() => {
    let filtered = [...allData.value];
    if (selectedEmployee.value) {
        filtered = filtered.filter(item =>
            item.empId.employeeName === selectedEmployee.value
        );
    }
    // Sort by date in descending order (latest first)
    return filtered.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate));
});

const openPhotoModal = (photoUrl) => {
    selectedPhoto.value = photoUrl;
    showPhotoModal.value = true;
};

onMounted(async () => {
    fetchGetApi('api/attendance/getAttendance').then((response) => {
        if (response?.data) {
            allData.value = response.data.data;
            if (response.data.pagination) {
                currentPage.value = response.data.pagination.currentPage;
                totalPages.value = response.data.pagination.totalPages;
            }
        }
    });
});

// Pagination methods
const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchData();
    }
};

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchData();
    }
};

const fetchData = () => {
    fetchGetApi(`api/attendance/getAttendance?page=${currentPage.value}`).then((response) => {
        if (response?.data) {
            allData.value = response.data.data;
            if (response.data.pagination) {
                totalPages.value = response.data.pagination.totalPages;
            }
        }
    });
};

</script>

<template>
    <Breadcrumbs main="Apps" title="Attendance List" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="employee-select" class="col-form-label">Select Employee:</label>
                                <select id="employee-select" class="form-select" v-model="selectedEmployee">
                                    <option value="">All Employees</option>
                                    <option v-for="employee in uniqueEmployees" :key="employee" :value="employee">
                                        {{ employee }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;">SL</th>
                                    <th scope="col">Employee Name</th>
                                    <th scope="col">Employee Code</th>
                                    <th scope="col">Entry Date</th>
                                    <th scope="col">Entry Time</th>
                                    <th scope="col">Exit Date</th>
                                    <th scope="col">Exit Time</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Photo</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="9" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in filteredData" :key="row._id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ row.empId.employeeName }}</td>
                                    <td>{{ row.empId.employeeCode }}</td>
                                    <td>{{ new Date(row.entryDate).toLocaleDateString() }}</td>
                                    <td>{{ row.entryTime }}</td>
                                    <td>{{ row.exitDate ? new Date(row.exitDate).toLocaleDateString() : '-' }}</td>
                                    <td>{{ row.exitTime || '-' }}</td>
                                    <td>
                                        Lat: {{ row.location.lat }}<br>
                                        Lng: {{ row.location.lng }}
                                    </td>
                                    <td>
                                        <img :src="row.photo" alt="Entry Photo"
                                            style="width: 50px; height: 50px; object-fit: cover; cursor: pointer;"
                                            @click="openPhotoModal(row.photo)" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                Showing page {{ currentPage }} of {{ totalPages }}
                            </div>
                            <ul class="pagination justify-content-end pagination-primary">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" @click="previousPage" style="cursor: pointer;">Previous</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" @click="nextPage" style="cursor: pointer;">Next</a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- Photo Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showPhotoModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Attendance Photo</h5>
                    <button type="button" class="btn-close" @click="showPhotoModal = false"></button>
                </div>
                <div class="modal-body text-center">
                    <img :src="selectedPhoto" alt="Attendance Photo" style="max-width: 100%; max-height: 70vh;" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    display: none;
}

.modal.d-block {
    display: block !important;
}
</style>
