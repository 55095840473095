<script lang="js" setup>
import { ref, onMounted, Ref, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
onMounted(async () => {
    getData();
});

function getData() {
    fetchGetApi('api/criteria/report').then((response) => {

        console.log(response);
        if (response && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item =>
        item.siteName.toLowerCase().includes(query) ||
        item.area.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

const paginatedData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item =>
        item.siteName.toLowerCase().includes(query) ||
        item.area.toLowerCase().includes(query)
    );

    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;

    return filteredData.slice(start, end);
});

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}





</script>
<template>
    <Breadcrumbs main="Apps" title="Criteria" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search" class="col-xs-12 col-sm-auto col-form-label">Site
                                    Name:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">SIte name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Area</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Post</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Minimum wages</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Working hour</th>
                                    <th class="sorting_asc  text-nowrap" scope="col">Salary</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Esi epf</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Stay</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Weekly off</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Food</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in paginatedData" :key="item.SL">
                                    <td>{{ item.SL }}</td>
                                    <td>{{ item.siteName }}</td>
                                    <td>{{ item.area }}</td>
                                    <td>{{ item.department }}</td>
                                    <td>{{ item.post }}</td>
                                    <td>{{ item.minimumWages }}</td>
                                    <td>{{ item.workingHour }}</td>
                                    <td>{{ item.salary }}</td>
                                    <td>{{ item.esiEpf }}</td>
                                    <td>{{ item.stay }}</td>
                                    <td>{{ item.weeklyOff }}</td>
                                    <td>{{ item.food }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>




        </div>
    </div>



</template>