<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import VueSlider from 'vue-slider-component'

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();
const minPercentage = ref(0);
const maxPercentage = ref(100);

const columnFilters = ref({
    projectName: '',
    managerName: '',
    area: '',
    servicesAndManpower: '',
    criteria: ''
});

const percentageRange = computed({
    get() {
        return [minPercentage.value, maxPercentage.value]
    },
    set([min, max]) {
        minPercentage.value = min
        maxPercentage.value = max
    }
});

onMounted(async () => {
    getData();
});

function getData() {
    fetchGetApi('api/percentage/report').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

const filteredData = computed(() => {
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filtered = data.filter(item => {
        const matchesPercentage = item.rating >= minPercentage.value && item.rating <= maxPercentage.value;

        const matchesProjectName = item.projectName?.toLowerCase().includes(columnFilters.value.projectName.toLowerCase());
        const matchesManager = item.managerName?.toLowerCase().includes(columnFilters.value.managerName.toLowerCase());
        const matchesArea = item.area?.toLowerCase().includes(columnFilters.value.area.toLowerCase());
        const matchesServices = item.servicesAndManpower?.toLowerCase().includes(columnFilters.value.servicesAndManpower.toLowerCase());
        const matchesCriteria = item.criteria?.toLowerCase().includes(columnFilters.value.criteria.toLowerCase());

        return matchesPercentage && matchesProjectName && matchesManager &&
            matchesArea && matchesServices && matchesCriteria;
    });

    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;

    return filtered.slice(start, end);
});

function getStatusClass(status) {
    switch (status) {
        case 'Excellent':
            return 'text-success';
        case 'Average':
            return 'text-warning';
        case 'Needs Improvement':
            return 'text-danger';
        default:
            return 'text-secondary';
    }
}

function num_pages() {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function viewDetails(item) {
    router.push({
        name: 'project-details',
        params: { id: item.slNo },
        query: { rating: item.rating }
    });
}

</script>
<template>
    <Breadcrumbs main="Apps" title="Percentage analysis" />
    <div class="container-fluid card ">
        <div class="email-wrap bookmark-wrap">
            <div class="row ">
                <div class="table-responsive product-table custom-scrollbar card-body">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between align-items-center">
                            <div class="col-xs-12 col-sm-6">
                                <div class="range-selector-container">
                                    <label class="form-label fw-bold mb-3">Percentage Range: {{ minPercentage }}% - {{
                                        maxPercentage }}%</label>
                                    <vue-slider v-model="percentageRange" :min="0" :max="100" :interval="1"
                                        :process="true" :tooltip="'always'" :contained="true" class="mx-4" />
                                </div>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table " id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Project Name
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.projectName" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Manager Name
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.managerName" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last date of report</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last report</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Area
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.area" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Service targeted and manpower
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.servicesAndManpower" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Total manpower</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Criterias
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.criteria" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc" scope="col">Quote/proposal submitted</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in filteredData" :key="item.slNo">
                                    <td>{{ item.slNo }}</td>
                                    <td>{{ item.projectName }}</td>
                                    <td>{{ item.managerName }}</td>
                                    <td>{{ item.lastReportDate }}</td>
                                    <td>{{ item.lastReport.join(', ') }}</td>
                                    <td>{{ item.area }}</td>
                                    <td>{{ item.servicesAndManpower }}</td>
                                    <td>{{ item.totalManpower }}</td>
                                    <td>{{ item.criteria }}</td>
                                    <td>
                                        <span :class="item.proposalStatus.status ? 'text-success' : 'text-danger'">
                                            {{ item.proposalStatus.text }}
                                        </span>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn-xs" @click="viewDetails(item)"
                                            title="View Details">
                                            <i class="fa fa-eye"></i>
                                            <!-- <span class="badge bg-secondary ms-1">{{ item.rating }}%</span> -->
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<style scoped>
.form-control-sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
}

.badge {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
}

/* Enhanced slider styles */
:deep(.vue-slider) {
    padding: 1.5rem 0;
    margin: 0 1rem;
}

:deep(.vue-slider-rail) {
    background-color: #e0e0e0;
    height: 6px;
    border-radius: 3px;
}

:deep(.vue-slider-process) {
    background-color: #7366ff;
    height: 6px;
    border-radius: 3px;
}

:deep(.vue-slider-dot) {
    width: 20px;
    height: 20px;
    top: -7px;
}

:deep(.vue-slider-dot-handle) {
    border: 2px solid #7366ff;
    box-shadow: 0 2px 4px rgba(115, 102, 255, 0.2);
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all 0.3s ease;
}

:deep(.vue-slider-dot-handle:hover) {
    transform: scale(1.1);
    box-shadow: 0 3px 6px rgba(115, 102, 255, 0.3);
}

:deep(.vue-slider-dot-tooltip) {
    background-color: #7366ff;
    border-color: #7366ff;
    padding: 4px 8px;
    color: white;
    font-size: 0.875rem;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

:deep(.vue-slider-dot-tooltip-inner) {
    border-color: #7366ff;
}

/* Add a container style for better spacing */
.range-selector-container {
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 1rem;
}
</style>