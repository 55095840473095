<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import * as XLSX from 'xlsx';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/operation/materialRequest/getAll').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data');
        }
    });
});

// ... pagination functions remain the same ...

const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    const data = Array.isArray(allData.value) ? allData.value : [];
    return data.filter(item =>
        item.category.toLowerCase().includes(query) ||
        item.status.toLowerCase().includes(query)
    ).slice((currentPage.value - 1) * elementsPerPage.value, currentPage.value * elementsPerPage.value);
});

function downloadExcel() {
    const worksheet = XLSX.utils.json_to_sheet(allData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Material Requests");
    XLSX.writeFile(workbook, 'material-requests.xlsx');
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();
    const data = Array.isArray(allData.value) ? allData.value : [];
    const filteredData = data.filter(item =>
        item.category.toLowerCase().includes(query) ||
        item.status.toLowerCase().includes(query)
    );
    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Material Request" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer" @submit.prevent>
                        <!-- Search and Download buttons -->
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search" class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>

                        <!-- Table -->
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col">SL</th>
                                    <th class="sorting_asc" scope="col">Category</th>
                                    <th class="sorting_asc" scope="col">Preferred Date</th>
                                    <th class="sorting_asc" scope="col">No. of Boots</th>
                                    <th class="sorting_asc" scope="col">No. of Umbrella</th>
                                    <th class="sorting_asc" scope="col">Registers</th>
                                    <th class="sorting_asc" scope="col">Checklists</th>
                                    <th class="sorting_asc" scope="col">Status</th>
                                    <th class="sorting_asc" scope="col">Created At</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="9" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in filteredData" :key="item._id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.category }}</td>
                                    <td>{{ item.preferred_date }}</td>
                                    <td>{{ item.noOfBoots }}</td>
                                    <td>{{ item.noOfUmbrella }}</td>
                                    <td>{{ item.register_checklist.noOfRegisters }}</td>
                                    <td>{{ item.register_checklist.noOfChecklists }}</td>
                                    <td>
                                        <span :class="{
                                            'badge rounded-pill bg-success': item.status === 'Approved',
                                            'badge rounded-pill bg-warning': item.status === 'Pending',
                                            'badge rounded-pill bg-danger': item.status === 'Rejected'
                                        }">{{ item.status }}</span>
                                    </td>
                                    <td>{{ new Date(item.createdAt).toLocaleDateString() }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- Pagination -->
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" 
                                v-for="i in num_pages()" 
                                :key="i" 
                                :class="[i == currentPage ? 'active' : '']" 
                                @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>