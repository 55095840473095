<template>
    <div class="content-wrapper">
        <div class="content-header">
            <h1></h1>
            <button class="btn btn-primary" @click="openAddModal">
                Add Area
            </button>
        </div>

        <div class="content">
            <div class="card">
                <div class="card-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Area Name</th>
                                <th>Description</th>
                                <th>Address</th>
                                <th>Pin Code</th>
                                <th>Location</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="area in areas" :key="area._id">
                                <td>{{ area.areaName }}</td>
                                <td>{{ area.areaDescription }}</td>
                                <td>{{ area.address }}</td>
                                <td>{{ area.pinCode }}</td>
                                <td>
                                    Lat: {{ area.areaLat }}, Long: {{ area.areaLong }}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-primary me-2" @click="openEditModal(area)">
                                        Edit
                                    </button>
                                    <button class="btn btn-sm btn-danger" @click="confirmDelete(area)">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Pagination -->
                    <div v-if="pagination" class="d-flex justify-content-between align-items-center mt-3">
                        <div>
                            Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                            {{ Math.min(currentPage * itemsPerPage, pagination.totalItems) }} of
                            {{ pagination.totalItems }} entries
                        </div>
                        <nav>
                            <ul class="pagination mb-0">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" href="#"
                                        @click.prevent="changePage(currentPage - 1)">Previous</a>
                                </li>
                                <li v-for="page in pagination.totalPages" :key="page" class="page-item"
                                    :class="{ active: page === currentPage }">
                                    <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === pagination.totalPages }">
                                    <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addAreaModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ isEditing ? 'Edit Area' : 'Add Area' }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label>Area Name</label>
                            <input type="text" class="form-control" v-model="areaForm.areaName" required>
                        </div>
                        <div class="form-group mb-3">
                            <label>Description</label>
                            <textarea class="form-control" v-model="areaForm.areaDescription" rows="3"></textarea>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Address</label>
                                    <input type="text" class="form-control" v-model="areaForm.address">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Pin Code</label>
                                    <input type="text" class="form-control" v-model="areaForm.pinCode" maxlength="6"
                                        pattern="\d{6}">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Latitude</label>
                                    <input type="number" step="0.000001" class="form-control"
                                        v-model="areaForm.areaLat">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Longitude</label>
                                    <input type="number" step="0.000001" class="form-control"
                                        v-model="areaForm.areaLong">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="saveArea">Save</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Delete Confirmation Modal -->
        <div class="modal fade" id="deleteConfirmModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm Delete</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to delete the area "{{ selectedArea?.areaName }}"?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="deleteArea">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { fetchGetApi, fetchPostApi } from "@/util/api";
import { Modal } from 'bootstrap';

const areas = ref([]);
const areaForm = ref({
    areaName: '',
    areaDescription: '',
    address: '',
    pinCode: '',
    areaLat: null,
    areaLong: null,
    _id: null
});
const isEditing = ref(false);
const currentPage = ref(1);
const itemsPerPage = ref(10);
const pagination = ref(null);
let modal = null;
const selectedArea = ref(null);
const deleteModal = ref(null);

onMounted(async () => {
    await loadAreas();
    modal = new Modal(document.getElementById('addAreaModal'));
    deleteModal.value = new Modal(document.getElementById('deleteConfirmModal'));
});

const loadAreas = async () => {
    try {
        const response = await fetchGetApi(`api/area/get/all?page=${currentPage.value}`);
        console.log(response.data.data);
        if (response && response.data.data) {
            areas.value = response.data.data;
            pagination.value = response.data.pagination || {
                currentPage: 1,
                totalPages: 1,
                totalItems: response.data.length,
                itemsPerPage: 10
            };
        }
    } catch (error) {
        console.error('Error loading areas:', error);
        areas.value = [];
        pagination.value = {
            currentPage: 1,
            totalPages: 1,
            totalItems: 0,
            itemsPerPage: 10
        };
    }
};

const changePage = async (page) => {
    if (page >= 1 && page <= pagination.value.totalPages) {
        currentPage.value = page;
        await loadAreas();
    }
};

const openAddModal = () => {
    isEditing.value = false;
    areaForm.value = {
        areaName: '',
        areaDescription: '',
        address: '',
        pinCode: '',
        areaLat: null,
        areaLong: null,
        _id: null
    };
    modal.show();
};

const openEditModal = (area) => {
    isEditing.value = true;
    areaForm.value = { ...area };
    modal.show();
};

const saveArea = async () => {
    try {
        if (isEditing.value) {
            await fetchPostApi(`api/area/edit/${areaForm.value._id}`, areaForm.value);
        } else {
            await fetchPostApi('api/area/add', areaForm.value);
        }
        await loadAreas();
        modal.hide();
    } catch (error) {
        console.error('Error saving area:', error);
    }
};

const confirmDelete = (area) => {
    selectedArea.value = area;
    deleteModal.value.show();
};

const deleteArea = async () => {
    try {
        await fetchPostApi(`api/area/delete/${selectedArea.value._id}`);
        await loadAreas();
        deleteModal.value.hide();
        selectedArea.value = null;
    } catch (error) {
        console.error('Error deleting area:', error);
    }
};
</script>

<style scoped>
.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.card {
    margin-bottom: 1rem;
}
</style>