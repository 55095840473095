<!-- src/components/site-management/NewSiteForm.vue -->
<template>
    <div class="site-form-container">
        <h4 class="mb-4">Create New Site</h4>
        <form @submit.prevent="handleSubmit" class="needs-validation">
            <!-- Basic Site Information -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Basic Site Information</h5>
                </div>
                <div class="card-body">
                    <BasicSiteInfo v-model:formData="formData.basicInfo" @file-upload="handleFileUpload" />
                </div>
            </div>

            <!-- Workforce Details -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Workforce Details</h5>
                </div>
                <div class="card-body">
                    <WorkforceDetails v-model:formData="formData.workforce"
                        @designation-change="handleDesignationChange" />
                </div>
            </div>

            <!-- SOP Section -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Standard Operating Procedures</h5>
                </div>
                <div class="card-body">
                    <SopSection v-model:sops="formData.sops" />
                </div>
            </div>

            <!-- Work Order Section -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Work Order Documents</h5>
                </div>
                <div class="card-body">
                    <WorkOrderSection v-model:workOrders="formData.workOrders" />
                </div>
            </div>

            <!-- Client Requirements -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Client Requirements</h5>
                </div>
                <div class="card-body">
                    <ClientRequirements v-model:requirements="formData.clientRequirements" />
                </div>
            </div>

            <!-- Billing Details -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Billing Details</h5>
                </div>
                <div class="card-body">
                    <BillingDetails v-model:billing="formData.billing" />
                </div>
            </div>

            <!-- Submit Button -->
            <div class="text-end">
                <button type="submit" class="btn btn-primary" :disabled="isSubmitting">
                    {{ isSubmitting ? 'Creating Site...' : 'Create Site' }}
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import { useToast } from 'vue-toast-notification';
import BasicSiteInfo from './basic-site-info.vue';
import WorkforceDetails from './work-force-details.vue';
import SopSection from './sop-section.vue';
import WorkOrderSection from './work-order-section.vue';
import ClientRequirements from './client-requirements.vue';
import BillingDetails from './billing-details.vue';
import { useRouter } from 'vue-router';
import { fetchPostApi } from '@/util/api';

const toast = useToast();
const isSubmitting = ref(false);
const router = useRouter();

const formData = reactive({
    basicInfo: {
        siteName: '',
        quotation: {
            type: '',
            file: null as File | null
        },
        workOrder: {
            type: '',
            file: null as File | null
        },
        client: {
            name: '',
            designation: '',
            phone: '',
            altPhone: '',
            email: ''
        },
        otherContact: {
            name: '',
            designation: '',
            phone: '',
            altPhone: '',
            email: ''
        },
        officeAddress: '',
        siteAddress: ''
    },
    workforce: {
        totalManpower: 0,
        workingPlace: '',
        designations: [],
        subDesignationDetails: {}
    },
    sops: [],
    workOrders: [],
    clientRequirements: {
        specificRequirements: '',
        serviceStartDate: {
            male: '',
            female: ''
        }
    },
    billing: {
        type: '',
        gstNumber: ''
    }
});

watch(() => formData, (newVal) => {
    console.log('Form Data Updated:', newVal);
}, { deep: true });

const validateForm = () => {
    if (!formData.basicInfo.siteName) {
        toast.error('Site name is required');
        return false;
    }
    
    if (!formData.basicInfo.officeAddress) {
        toast.error('Office address is required');
        return false;
    }
    
    if (!formData.basicInfo.siteAddress) {
        toast.error('Site address is required');
        return false;
    }

    if (!formData.workforce.totalManpower) {
        toast.error('Total manpower is required');
        return false;
    }

    if (!formData.billing.type) {
        toast.error('Billing type is required');
        return false;
    }

    return true;
};

const handleSubmit = async () => {
    try {
        if (!validateForm()) {
            return;
        }

        isSubmitting.value = true;
        const submitFormData = new FormData();

        // Basic Site Information
        submitFormData.append('siteName', formData.basicInfo.siteName);
        submitFormData.append('officeAddress', formData.basicInfo.officeAddress);
        submitFormData.append('siteAddress', formData.basicInfo.siteAddress);
        submitFormData.append('quotationType', formData.basicInfo.quotation.type);
        submitFormData.append('workOrderType', formData.basicInfo.workOrder.type);

        // Workforce Details
        const workforceData = {
            totalManpower: Number(formData.workforce.totalManpower),
            workingPlace: formData.workforce.workingPlace,
            designations: formData.workforce.designations,
            subDesignationDetails: Object.fromEntries(
                Object.entries(formData.workforce.subDesignationDetails).map(([key, value]) => [
                    key,
                    {
                        manpower: value.manpower,
                        weeklyOff: value.weeklyOff,
                        workingHours: value.workingHours,
                        esiEpf: value.esiEpf,
                        food: value.food,
                        accommodation: value.accommodation,
                        deadline: value.deadline,
                        inHandSalary: value.inHandSalary,
                        quotedRate: value.quotedRate,
                        serviceStartDate: value.serviceStartDate
                    }
                ])
            )
        };
        submitFormData.append('workforce', JSON.stringify(workforceData));

        // SOPs
        const sopsData = formData.sops.map(sop => ({
            question: sop.question,
            answer: sop.answer
        }));
        submitFormData.append('sops', JSON.stringify(sopsData));

        // Client Requirements
        const clientRequirementsData = {
            specificRequirements: formData.clientRequirements.specificRequirements,
            serviceStartDate: {
                male: formData.clientRequirements.serviceStartDate.male,
                female: formData.clientRequirements.serviceStartDate.female
            }
        };
        submitFormData.append('clientRequirements', JSON.stringify(clientRequirementsData));

        // Billing Details
        const billingData = {
            type: formData.billing.type,
            gstNumber: formData.billing.gstNumber
        };
        submitFormData.append('billing', JSON.stringify(billingData));

        // Client Information
        const clientData = {
            name: formData.basicInfo.client.name,
            designation: formData.basicInfo.client.designation,
            phone: formData.basicInfo.client.phone,
            email: formData.basicInfo.client.email,
            altPhone: formData.basicInfo.client.altPhone
        };
        submitFormData.append('client', JSON.stringify(clientData));

        // Contact Person Information (if provided)
        if (formData.basicInfo.otherContact.name) {
            const contactPersonData = {
                name: formData.basicInfo.otherContact.name,
                designation: formData.basicInfo.otherContact.designation,
                phone: formData.basicInfo.otherContact.phone,
                email: formData.basicInfo.otherContact.email,
                altPhone: formData.basicInfo.otherContact.altPhone
            };
            submitFormData.append('contactPerson', JSON.stringify(contactPersonData));
        }

        // File Uploads
        if (formData.basicInfo.quotation.file) {
            submitFormData.append('quotation', formData.basicInfo.quotation.file);
        }

        if (formData.basicInfo.workOrder.file) {
            submitFormData.append('workOrder', formData.basicInfo.workOrder.file);
        }

        // Additional Work Orders
        if (formData.workOrders.length > 0) {
            formData.workOrders.forEach(file => {
                submitFormData.append('additionalWorkOrders', file);
            });
        }

        // Make API call
        const response = await fetchPostApi(
            `api/temp/add/site`, 
            submitFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.data.success) {
            toast.success('Site created successfully');
            router.push('/site-creation/list');
        } else {
            throw new Error(response.data.message || 'Error creating site');
        }
    } catch (error) {
        console.error('Error creating site:', error);
        toast.error(error.message || 'Error creating site');
    } finally {
        isSubmitting.value = false;
    }
};

const handleFileUpload = (type: string, file: File) => {
    if (type === 'quotation') {
        formData.basicInfo.quotation.file = file;
    } else if (type === 'workOrder') {
        formData.basicInfo.workOrder.file = file;
    }
};
const handleDesignationChange = (designations: string[]) => {
    formData.workforce.designations = designations as never[];
};
</script>

<style scoped>
.site-form-container {
    padding: 20px;
}

.card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
}

.card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.btn-primary {
    min-width: 120px;
}
</style>