import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row mb-4" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "btn-group" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "row g-3" }
const _hoisted_7 = { class: "card h-100" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "card-title" }
const _hoisted_11 = { class: "card-text" }
const _hoisted_12 = { class: "btn-group" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  class: "modal fade",
  id: "galleryModal",
  tabindex: "-1",
  role: "dialog",
  "aria-labelledby": "galleryModalLabel",
  "aria-hidden": "true"
}
const _hoisted_16 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_17 = { class: "modal-content" }
const _hoisted_18 = { class: "modal-header" }
const _hoisted_19 = { class: "modal-title" }
const _hoisted_20 = { class: "modal-body" }
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "mb-3" }
const _hoisted_24 = ["value"]
const _hoisted_25 = { class: "mb-3" }
const _hoisted_26 = { class: "text-end" }

import { ref, computed, onMounted } from 'vue'
import { useToast } from 'vue-toast-notification'
import { fetchGetApi, fetchPostApi, fetchPutApi, fetchDeleteApi } from '@/util/api'
import { Modal } from 'bootstrap'

interface GalleryImage {
    _id: string
    url: string
    key: string
    category: 'Events' | 'Office' | 'Projects' | 'Team'
    title: string
    description?: string
    order: number
}

interface FormData {
    _id?: string
    url: string
    key: string
    category: GalleryImage['category'] | ''
    title: string
    description: string
    order: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'gallery',
  setup(__props) {

const baseUrl = process.env.VUE_APP_BASE_URL;
const toast = useToast()
const categories = ['All', 'Events', 'Office', 'Projects', 'Team'] as const
const selectableCategories = categories.filter(c => c !== 'All') as GalleryImage['category'][]
const selectedCategory = ref<typeof categories[number]>('All')
const isEditing = ref(false)
const images = ref<GalleryImage[]>([])
//@ts-ignore
const modal = ref<Modal | null>(null)

const formData = ref<FormData>({
    url: '',
    key: '',
    category: '',
    title: '',
    description: '',
    order: 0
})

const filteredImages = computed(() => {
    if (selectedCategory.value === 'All') return images.value
    return images.value.filter(img => img.category === selectedCategory.value)
})

function openModal() {
    if (!modal.value) {
        modal.value = new Modal(document.getElementById('galleryModal'))
    }
    modal.value.show()
}

function hideModal() {
    if (modal.value) {
        modal.value.hide()
        resetForm()
    }
}

async function fetchImages() {
    try {
        const params = selectedCategory.value !== 'All' ? `?category=${selectedCategory.value}` : '';
        const response = await fetchGetApi(`api/gallery${params}`)
        if (response.data.success) {
            images.value = response.data.data
        } else {
            throw new Error('Failed to fetch images')
        }
    } catch (error) {
        toast.error('Failed to load images')
        console.error('Error fetching images:', error)
    }
}

async function saveImage() {
    try {
        if (isEditing.value && formData.value._id) {
            // Update existing image
            const response = await fetchPutApi(
                `api/gallery/${formData.value._id}`,
                {
                    title: formData.value.title,
                    description: formData.value.description,
                    category: formData.value.category,
                    order: formData.value.order
                }
            )

            if (!response.data.success) {
                throw new Error('Failed to update image')
            }
        } else {
            // Create new image
            const imageFormData = new FormData()
            if (formData.value.file) {
                imageFormData.append('image', formData.value.file)
            }
            imageFormData.append('title', formData.value.title)
            imageFormData.append('description', formData.value.description || '')
            imageFormData.append('category', formData.value.category as string)
            imageFormData.append('order', formData.value.order.toString())

            const response = await fetchPostApi(
                `api/gallery/upload`,
                imageFormData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )

            if (!response.data.success) {
                throw new Error('Failed to create image')
            }
        }

        hideModal()
        await fetchImages()
        toast.success(`Image ${isEditing.value ? 'updated' : 'added'} successfully`)
    } catch (error) {
        toast.error('Failed to save image')
        console.error('Error saving image:', error)
    }
}

async function deleteImage(image: GalleryImage) {
    if (!confirm('Are you sure you want to delete this image?')) return

    try {
        const response = await fetchDeleteApi(`api/gallery/${image._id}`)
        if (response.data.success) {
            await fetchImages()
            toast.success('Image deleted successfully')
        } else {
            throw new Error('Failed to delete image')
        }
    } catch (error) {
        toast.error('Failed to delete image')
        console.error('Error deleting image:', error)
    }
}

async function updateOrder(evt: any) {
    try {
        const updates = filteredImages.value.map((img, index) => ({
            key: img.key,
            order: index
        }))

        await fetchPutApi(`api/gallery/reorder`, updates)
        toast.success('Order updated successfully')
    } catch (error) {
        toast.error('Failed to update order')
        console.error('Error updating order:', error)
    }
}

function editImage(image: GalleryImage) {
    isEditing.value = true
    formData.value = {
        _id: image._id,
        url: image.url,
        key: image.key,
        category: image.category,
        title: image.title,
        description: image.description || '',
        order: image.order
    }
    openModal()
}

async function handleImageUpload(event: Event) {
    const target = event.target as HTMLInputElement
    const file = target.files?.[0]
    if (!file) return

    // Add file type validation
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp']
    if (!allowedTypes.includes(file.type)) {
        toast.error('Invalid file type. Only JPEG, PNG and WebP are allowed.')
        return
    }

    // Store the file in formData for later upload
    formData.value.file = file
}

function resetForm() {
    formData.value = {
        url: '',
        key: '',
        category: '',
        title: '',
        description: '',
        order: images.value.length // Set order to last position for new images
    }
    isEditing.value = false
}

function handleAddImage() {
    isEditing.value = false
    resetForm()
    openModal()
}

// Initialize
onMounted(() => {
    fetchImages()
})

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Content",
      title: "Gallery Management"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(categories, (category) => {
              return _createElementVNode("button", {
                key: category,
                class: _normalizeClass(["btn", selectedCategory.value === category ? 'btn-primary' : 'btn-outline-primary']),
                onClick: ($event: any) => (selectedCategory.value = category)
              }, _toDisplayString(category), 11, _hoisted_5)
            }), 64))
          ])
        ]),
        _createElementVNode("div", { class: "col-auto" }, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: handleAddImage
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("i", { class: "fa fa-plus" }, null, -1),
            _createTextVNode(" Add Image ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_TransitionGroup, { name: "gallery-list" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredImages.value, (image) => {
              return (_openBlock(), _createElementBlock("div", {
                key: image.key,
                class: "col-md-4 col-lg-3"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: image.url,
                    class: "card-img-top",
                    alt: image.title
                  }, null, 8, _hoisted_8),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("h5", _hoisted_10, _toDisplayString(image.title), 1),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(image.description), 1),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("button", {
                        class: "btn btn-sm btn-primary",
                        onClick: ($event: any) => (editImage(image))
                      }, _cache[6] || (_cache[6] = [
                        _createElementVNode("i", { class: "fa fa-edit" }, null, -1)
                      ]), 8, _hoisted_13),
                      _createElementVNode("button", {
                        class: "btn btn-sm btn-danger",
                        onClick: ($event: any) => (deleteImage(image))
                      }, _cache[7] || (_cache[7] = [
                        _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                      ]), 8, _hoisted_14)
                    ])
                  ])
                ])
              ]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("h5", _hoisted_19, _toDisplayString(isEditing.value ? 'Edit Image' : 'Add Image'), 1),
              _createElementVNode("button", {
                class: "btn-close",
                type: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (hideModal())),
                "aria-label": "Close"
              })
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("form", {
                onSubmit: _withModifiers(saveImage, ["prevent"])
              }, [
                _createElementVNode("div", _hoisted_21, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label" }, "Image", -1)),
                  _createElementVNode("input", {
                    type: "file",
                    class: "form-control",
                    onChange: handleImageUpload,
                    accept: "image/*"
                  }, null, 32)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label" }, "Title", -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.title) = $event)),
                    type: "text",
                    class: "form-control",
                    required: ""
                  }, null, 512), [
                    [_vModelText, formData.value.title]
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-label" }, "Category", -1)),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.category) = $event)),
                    class: "form-select",
                    required: ""
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectableCategories), (category) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: category,
                        value: category
                      }, _toDisplayString(category), 9, _hoisted_24))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, formData.value.category]
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, "Description", -1)),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.description) = $event)),
                    class: "form-control",
                    rows: "3"
                  }, null, 512), [
                    [_vModelText, formData.value.description]
                  ])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-secondary me-2",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (hideModal()))
                  }, "Cancel"),
                  _cache[12] || (_cache[12] = _createElementVNode("button", {
                    type: "submit",
                    class: "btn btn-primary"
                  }, "Save", -1))
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})