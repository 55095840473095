<script setup>
import { ref, onMounted, computed } from 'vue';
import { fetchGetApi } from "@/util/api";
import { useToast } from 'vue-toast-notification';

const projectData = ref([
    {
        totalCalls: 10,
        totalVisits: 5,
        percentage: 30,
        dateTime: '25.04.2024 11:10 am',
        managerName: 'Kanai Basfor',
        personMet: 'Mr.XYZ',
        designation: 'President',
        socSubmitted: true,
        meetingStatus: '1. Quotation needs to be submitted\n2. Finalisation will take place',
        hasQuotation: true,
        hasProposal: true,
        nfdDate: '28.04.2024',
        kytCount: 3
    },
    {
        totalCalls: 8,
        totalVisits: 3,
        percentage: 45,
        dateTime: '26.04.2024 02:30 pm',
        managerName: 'John Doe',
        personMet: 'Mrs.ABC',
        designation: 'Manager',
        socSubmitted: false,
        meetingStatus: '1. Initial discussion completed\n2. Waiting for response',
        hasQuotation: false,
        hasProposal: true,
        nfdDate: '29.04.2024',
        kytCount: 2
    },
    // Add more dummy data as needed
]);

// Filter refs
const dateFilter = ref('');
const percentageFilter = ref('');
const managerFilter = ref('');
const statusFilter = ref('all'); // 'all', 'submitted', 'pending'

// Computed filtered data
const filteredData = computed(() => {
    return projectData.value.filter(item => {
        const matchesDate = !dateFilter.value || item.dateTime.includes(dateFilter.value);
        const matchesPercentage = !percentageFilter.value || item.percentage.toString().includes(percentageFilter.value);
        const matchesManager = !managerFilter.value || 
            item.managerName.toLowerCase().includes(managerFilter.value.toLowerCase());
        const matchesStatus = statusFilter.value === 'all' || 
            (statusFilter.value === 'submitted' && item.socSubmitted) ||
            (statusFilter.value === 'pending' && !item.socSubmitted);

        return matchesDate && matchesPercentage && matchesManager && matchesStatus;
    });
});

function clearFilters() {
    dateFilter.value = '';
    percentageFilter.value = '';
    managerFilter.value = '';
    statusFilter.value = 'all';
}

function viewDetails(type) {
    console.log(`Viewing ${type} details`);
    // Handle different view actions
}
</script>

<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Filters Section -->
                <div class="row mb-4">
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Date Filter</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="dateFilter"
                            placeholder="Filter by date">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Percentage Filter</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="percentageFilter"
                            placeholder="Filter by percentage">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Manager Filter</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="managerFilter"
                            placeholder="Filter by manager name">
                    </div>
                    <div class="col-md-2 mb-2">
                        <label class="form-label">Status</label>
                        <select class="form-select" v-model="statusFilter">
                            <option value="all">All</option>
                            <option value="submitted">Submitted</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button 
                            class="btn btn-secondary" 
                            @click="clearFilters">
                            Clear
                        </button>
                    </div>
                </div>

                <!-- Table Section -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Sl no</th>
                                <th>Type Call/visit
                                    <div class="small">Total call:</div>
                                    <div class="small">Total visit:</div>
                                </th>
                                <th>Percentage</th>
                                <th>Date and time</th>
                                <th>Manager name</th>
                                <th>Person met and designation</th>
                                <th>SOC report submitted</th>
                                <th>Meeting report (last status)</th>
                                <th>criteria</th>
                                <th>Quotation</th>
                                <th>proposal</th>
                                <th>NFD</th>
                                <th>KYT change/transfer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredData" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    Call:{{ item.totalCalls }}
                                    <br>
                                    Visit:{{ item.totalVisits }}
                                </td>
                                <td>{{ item.percentage }}%</td>
                                <td>{{ item.dateTime }}</td>
                                <td>{{ item.managerName }}</td>
                                <td>{{ item.personMet }}<br>{{ item.designation }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span :class="item.socSubmitted ? 'text-success' : 'text-danger'">
                                            {{ item.socSubmitted ? 'Yes' : 'No' }}
                                        </span>
                                        <button v-if="item.socSubmitted" 
                                            class="btn btn-primary btn-xs ms-2"
                                            @click="viewDetails('soc')">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </td>
                                <td>{{ item.meetingStatus }}</td>
                                <td>
                                    <button class="btn btn-primary btn-xs" @click="$router.push('/reports/criteria')">
                                        view
                                    </button>
                                    <div class="small mt-1">
                                        Esi pf<br>
                                        Food<br>
                                        Working hour<br>
                                        Stay<br>
                                        Weekly off<br>
                                        Gender male female
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.hasQuotation">
                                        <button class="btn btn-primary btn-xs" @click="viewDetails('quotation')">
                                            View
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.hasProposal">
                                        <button class="btn btn-primary btn-xs" @click="viewDetails('proposal')">
                                            View
                                        </button>
                                    </div>
                                </td>
                                <td>{{ item.nfdDate }}</td>
                                <td>
                                    {{ item.kytCount }}
                                    <button class="btn btn-primary btn-xs ms-2" @click="$router.push('/reports/marketing-kyt-change-transfer')">
                                        (view)
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.table th, .table td {
    vertical-align: middle;
    font-size: 0.875rem;
}

.small {
    font-size: 0.875rem;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}
</style>