<script setup>
import { ref, computed, onMounted } from 'vue';
import { fetchGetApi } from "@/util/api";
import { useToast } from 'vue-toast-notification';

// Filter refs
const siteFilter = ref('');
const serviceFilter = ref('');
const areaFilter = ref('');
const competitorFilter = ref('');

// Data ref
const socData = ref([]);
const $toast = useToast();

// Fetch data function
async function fetchSocData() {
    try {
        const response = await fetchGetApi('api/reports/soc-analysis');

        socData.value = response.data;
    } catch (error) {
        $toast.error('Failed to fetch SOC data');
        console.error('Error fetching SOC data:', error);
    }
}

// Fetch data on component mount
onMounted(() => {
    fetchSocData();
});

// Computed filtered data
const filteredData = computed(() => {
    return socData.value.filter(item => {
        const matchesSite = !siteFilter.value ||
            item.projectName.toLowerCase().includes(siteFilter.value.toLowerCase());
        const matchesService = !serviceFilter.value ||
            item.modeOfService.toLowerCase().includes(serviceFilter.value.toLowerCase());
        const matchesArea = !areaFilter.value ||
            item.area.toLowerCase().includes(areaFilter.value.toLowerCase());
        const matchesCompetitor = !competitorFilter.value ||
            item.specialMention.toLowerCase().includes(competitorFilter.value.toLowerCase());

        return matchesSite && matchesService && matchesArea && matchesCompetitor;
    });
});

function clearFilters() {
    siteFilter.value = '';
    serviceFilter.value = '';
    areaFilter.value = '';
    competitorFilter.value = '';
}
</script>

<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Filters Section -->
                <div class="row mb-4">
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Site Filter</label>
                        <input type="text" class="form-control" v-model="siteFilter" placeholder="Filter by site">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Service Filter</label>
                        <input type="text" class="form-control" v-model="serviceFilter" placeholder="Filter by service">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Area Filter</label>
                        <input type="text" class="form-control" v-model="areaFilter" placeholder="Filter by area">
                    </div>
                    <div class="col-md-2 mb-2">
                        <label class="form-label">Competitor Filter</label>
                        <input type="text" class="form-control" v-model="competitorFilter"
                            placeholder="Filter by competitor">
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button class="btn btn-secondary" @click="clearFilters">Clear</button>
                    </div>
                </div>

                <!-- Table Section -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Sl no</th>
                                <th>Date</th>
                                <th>Report type</th>
                                <th>Reported by</th>
                                <th>Area</th>
                                <th>Project name</th>
                                <th>Mode of Service</th>
                                <th>Designation</th>
                                <th>Service wise manpower</th>
                                <th>Agency Name</th>
                                <th>Total manpower</th>
                                <th>Working hour</th>
                                <th>Minimum wages</th>
                                <th>Salary</th>
                                <th>Food</th>
                                <th>Stay</th>
                                <th>ESIC/PF</th>
                                <th>Weekly off</th>
                                <th>Agreement Duration</th>
                                <th>Special mention</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredData" :key="index">
                                <td>{{ item.SL }}</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.reportType }}</td>
                                <td>{{ item.reportedBy }}</td>
                                <td>{{ item.area }}</td>
                                <td>{{ item.projectName }}</td>
                                <td>{{ item.modeOfService }}</td>
                                <td>{{ item.designation }}</td>
                                <td>{{ item.serviceWiseManpower }}</td>
                                <td>{{ item.agencyName }}</td>
                                <td>{{ item.totalManpower }}</td>
                                <td>{{ item.workingHour }}</td>
                                <td>{{ item.minimumWages }}</td>
                                <td>{{ item.salary }}</td>
                                <td>{{ item.food }}</td>
                                <td>{{ item.stay }}</td>
                                <td>{{ item.esicPf }}</td>
                                <td>{{ item.weeklyOff }}</td>
                                <td>{{ item.agreementDuration }}</td>
                                <td>{{ item.specialMention }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table th,
.table td {
    vertical-align: middle;
    font-size: 0.875rem;
    white-space: nowrap;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
</style>