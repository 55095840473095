<!-- src/components/site-management/sections/BillingDetails.vue -->
<template>
    <div class="billing-details">
        <div class="row">
            <div class="col-md-6">
                <label class="form-label">Billing Type*</label>
                <select class="form-select" v-model="localBilling.type" required>
                    <option value="">Select Billing Type</option>
                    <option value="estimate">Estimate</option>
                    <option value="gst">GST</option>
                </select>
            </div>

            <!-- Additional billing fields can be added here based on the selected type -->
            <div class="col-md-6" v-if="localBilling.type === 'gst'">
                <label class="form-label">GST Number</label>
                <input type="text" class="form-control" v-model="localBilling.gstNumber"
                    pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                    placeholder="Enter GST Number" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue';

const props = defineProps<{
    billing: {
        type: string;
        gstNumber?: string;
    };
}>();

const emit = defineEmits(['update:billing']);

const localBilling = reactive({
    type: props.billing.type || '',
    gstNumber: props.billing.gstNumber || ''
});

watch(localBilling, (newVal) => {
    emit('update:billing', { ...newVal });
}, { deep: true });
</script>