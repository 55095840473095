<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';

const route = useRoute();
const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

// Add date range refs
const startDate = ref(null);
const endDate = ref(null);

onMounted(async () => {
    getData();
});

function getData() {
    const pathSegments = route.path.split('/');
    const areaId = pathSegments[pathSegments.length - 1];

    console.log('Area ID:', areaId);

    if (!areaId) {
        toast.error('Area ID is missing');
        return;
    }

    // Only add date parameters if both dates are selected
    let url = `api/operation/get/project/by/area/${areaId}`;
    if (startDate.value && endDate.value) {
        url += `?startDate=${startDate.value}&endDate=${endDate.value}`;
    }

    fetchGetApi(url).then((response) => {
        console.log('API Response:', response.data.data);
        allData.value = response.data.data;
    }).catch(error => {
        console.error('API Error:', error);
        toast.error('Error fetching data');
    });
}

// Add function to handle date changes
function handleDateChange() {
    getData();
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item =>
        item.ProjectName.toLowerCase().includes(query) ||
        item.AreaName.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

// Add computed property for filtered data
const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return allData.value.filter(item =>
        item.ProjectName.toLowerCase().includes(query) ||
        item.AreaName.toLowerCase().includes(query)
    ).slice(
        (currentPage.value - 1) * elementsPerPage.value,
        currentPage.value * elementsPerPage.value
    );
});

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}




</script>
<template>
    <Breadcrumbs main="Apps" title="Area - Project analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card">
                <!-- Add date range filters -->
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Start Date</label>
                                <input type="date" class="form-control" v-model="startDate" @change="handleDateChange">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>End Date</label>
                                <input type="date" class="form-control" v-model="endDate" @change="handleDateChange">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive card-body product-table custom-scrollbar">
                    <form class="no-footer">
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name of area</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name of project</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage (last visit)</th>
                                    <th class="sorting_asc text-nowrap" scope="col">No. of visits and no of visits
                                        inception</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last status</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Total no of manpower</th>
                                    <th class="sorting_asc  text-nowrap" scope="col">Service required</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Visits</th>
                                    <th class="sorting_asc" scope="col">Calls</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Proposal</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Quote</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredData" :key="index">
                                    <td>{{ ((currentPage - 1) * elementsPerPage) + index + 1 }}</td>
                                    <td>{{ item.AreaName }}</td>
                                    <td>
                                        <router-link :to="`/reports/area/area-project-view-details`"
                                            class="text-primary me-2" title="View" target="_blank">
                                            {{ item.ProjectName }}
                                        </router-link>
                                    </td>
                                    <td>{{ item.PercentageLastVisit }} %</td>
                                    <td>
                                        Total Visits: {{ item.TotalVisits }}
                                    </td>
                                    <td>{{ item.LastStatus.length ? item.LastStatus : 'Pending' }}</td>
                                    <td>{{ item.TotalManpower }}</td>
                                    <td>{{ item.ServicesRequired.join(', ') || '-' }}</td>
                                    <td>{{ item.MarketingManagerVisits.length ? 'Yes' : 'No' }}</td>
                                    <td>{{ item.MarketingManagerCalls.length ? 'Yes' : 'No' }}</td>
                                    <td>{{ item.ProposalSubmitted ? 'Yes' : 'No' }}</td>
                                    <td>{{ item.QuoteSubmitted ? 'Yes' : 'No' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>