<template>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card">
                    <div>
                        <div></div>
                        <div class="login-main">
                            <form class="theme-form" @submit.prevent="">
                                <h4 class="text-center">Sign in to account</h4>
                                <p class="text-center">Enter your email & password to login</p>
                                <div class="form-group">
                                    <label class="col-form-label">Email Address</label>
                                    <input v-model="email" class="form-control" type="email"
                                        placeholder="Test@gmail.com">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Password</label>
                                    <div class="form-input position-relative">
                                        <input v-model="password" :type="type" class="form-control"
                                            name="login[password]" placeholder="*********">
                                        <div class="show-hide"><span class="show" @click="showPassword"> </span></div>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="checkbox p-0">
                                        <input id="checkbox1" type="checkbox">
                                        <label class="text-muted" for="checkbox1">Remember password</label>
                                    </div><a class="link"><router-link to="/authentication/forgetpassword">Forgot
                                            password?</router-link></a>
                                    <div class="text-end mt-3">
                                        <button class="btn btn-primary btn-block w-100" type="submit"
                                            @click="doLogin">Sign
                                            in </button>
                                    </div>
                                </div>
                                <div class="login-social-title">
                                    <h6>Or Sign in with </h6>
                                </div>
                                <div class="form-group">
                                    <ul class="login-social">
                                        <li><a href="https://www.linkedin.com" target="_blank"><vue-feather
                                                    type="linkedin"></vue-feather></a></li>
                                        <li><a href="https://twitter.com" target="_blank"><vue-feather
                                                    type="twitter"></vue-feather></a></li>
                                        <li><a href="https://www.facebook.com" target="_blank"><vue-feather
                                                    type="facebook"></vue-feather></a></li>
                                        <li><a href="https://www.instagram.com" target="_blank"><vue-feather
                                                    type="instagram"></vue-feather></a></li>
                                    </ul>
                                </div>
                                <p class="mt-4 mb-0 text-center">Don't have account?<router-link class="ms-2"
                                        to="/auth/register">Create Account</router-link></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, nextTick } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useRouter } from 'vue-router';
import { useProjectStore } from "@/store/project";
import axios from 'axios';
import { useMenuStore } from "@/store/menu";

const baseUrl = process.env.VUE_APP_BASE_URL;
const store = useProjectStore();
const toaster = createToaster({});
const router = useRouter();
const type = ref<string>('password');

const email = ref<string>("");
const password = ref<string>("");

// Add axios interceptor to set token in headers
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}

async function doLogin() {
    console.log('Attempting to login...');
    try {
        const response = await axios.post(`${baseUrl}api/user/login`, {
            email: email.value,
            password: password.value
        });

        console.log('Login successful:', response.data);

        // Store the token
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        
        // Set sidebar type
        localStorage.setItem("SidebarType", 'compact-wrapper');
        
        // Initialize menu store
        const menuStore = useMenuStore();
        await menuStore.initializeMenu();
        
        // Force a router navigation
        await router.push('/');
        
        // Force page reload to ensure everything is initialized
        window.location.reload();

    } catch (error: any) {
        console.log('Login failed:', error);
        toaster.show(error.response.data.message, { 
            theme: 'outline', 
            position: 'top-right', 
            icon: 'times', 
            type: 'error', 
            duration: 2000 
        });
    }
}

function logout() {
    router.replace('/auth/login');
    localStorage.clear('user');
}
</script>