<script setup>
import { ref, computed, onMounted } from 'vue';
import { fetchGetApi } from "@/util/api";
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const kytData = ref([]);
const loading = ref(false);

// Filter refs
const managerFilter = ref('');
const dateRangeStart = ref('');
const dateRangeEnd = ref('');
const typeFilter = ref('all'); // 'all', 'transfer', 'change'

async function fetchData() {
    loading.value = true;
    try {
        // Fetch transfer KYTs
        const transferResponse = await fetchGetApi('api/kyt/transfer/get/all');
        console.log('Transfer Response:', transferResponse); // Debug log

        const transferKyts = (transferResponse?.data || []).map(item => ({
            managerName: item.requestedBy || 'N/A',
            date: new Date(item.createdAt).toLocaleDateString(),
            kytDate: new Date(item.createdAt).toLocaleDateString(),
            type: 'Transfer',
            projectName: item.siteName || 'N/A',
            workDetails: `KYT Transfer Request`,
            reason: item.reason || 'N/A',
            newNFD: new Date(item.updatedAt).toLocaleDateString(),
            transferredTo: item.empId || 'N/A',
            status: item.status || 'Pending'
        }));

        // Fetch change KYTs
        const changeResponse = await fetchGetApi('api/kyt/getChangeKyts');
        console.log('Change Response:', changeResponse); // Debug log

        const changeKyts = (changeResponse?.data || []).map(item => ({
            managerName: item.empName || 'N/A',
            date: new Date(item.date).toLocaleDateString(),
            kytDate: new Date(item.createdAt).toLocaleDateString(),
            type: 'Change',
            projectName: item.siteName || 'N/A',
            workDetails: `KYT Log: ${item.kytLogNo || 'N/A'}`,
            reason: item.reason || 'N/A',
            newNFD: new Date(item.updatedAt).toLocaleDateString(),
            transferredTo: '-',
            status: item.status || 'Pending'
        }));

        // Combine both arrays
        kytData.value = [...transferKyts, ...changeKyts];
        console.log('Combined KYT Data:', kytData.value); // Debug log

        if (kytData.value.length === 0) {
            $toast.info('No KYT data found');
        }
    } catch (error) {
        console.error('Error fetching KYT data:', error);
        $toast.error('Failed to fetch KYT data: ' + (error.message || 'Unknown error'));
    } finally {
        loading.value = false;
    }
}

onMounted(() => {
    fetchData();
});

// Computed filtered data
const filteredData = computed(() => {
    return kytData.value.filter(item => {
        const matchesManager = !managerFilter.value || 
            item.managerName.toLowerCase().includes(managerFilter.value.toLowerCase());
        
        const itemDate = new Date(item.date);
        const startDate = dateRangeStart.value ? new Date(dateRangeStart.value) : null;
        const endDate = dateRangeEnd.value ? new Date(dateRangeEnd.value) : null;
        
        const matchesDateRange = (!startDate || itemDate >= startDate) && 
                               (!endDate || itemDate <= endDate);
        
        const matchesType = typeFilter.value === 'all' || 
            item.type.toLowerCase() === typeFilter.value.toLowerCase();

        return matchesManager && matchesDateRange && matchesType;
    });
});

function clearFilters() {
    managerFilter.value = '';
    dateRangeStart.value = '';
    dateRangeEnd.value = '';
    typeFilter.value = 'all';
}

function viewWorkDetails(details) {
    console.log('Viewing work details:', details);
    // Implement modal or navigation to show full work details
}
</script>

<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Title Section -->
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <h5 class="card-title">Marketing KYT Change/Transfer Report</h5>
                </div>

                <!-- Filters Section -->
                <div class="row mb-4">
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Manager Name</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="managerFilter"
                            placeholder="Filter by manager">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Date Range Start</label>
                        <input 
                            type="date" 
                            class="form-control" 
                            v-model="dateRangeStart">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Date Range End</label>
                        <input 
                            type="date" 
                            class="form-control" 
                            v-model="dateRangeEnd">
                    </div>
                    <div class="col-md-2 mb-2">
                        <label class="form-label">Type</label>
                        <select class="form-select" v-model="typeFilter">
                            <option value="all">All</option>
                            <option value="transfer">Transfer</option>
                            <option value="change">Change</option>
                        </select>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button 
                            class="btn btn-secondary" 
                            @click="clearFilters">
                            Clear
                        </button>
                    </div>
                </div>

                <!-- Loading State -->
                <div v-if="loading" class="text-center my-4">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <!-- No Data Message -->
                <div v-else-if="!filteredData.length" class="text-center my-4">
                    <p class="text-muted">No KYT data available</p>
                </div>

                <!-- Table Section -->
                <div class="table-responsive" v-else>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Manager name</th>
                                <th>Date</th>
                                <th>KYT date</th>
                                <th>Type (transfer/change)</th>
                                <th>Name of project/CDW</th>
                                <th>Work details</th>
                                <th>Reason</th>
                                <th>New NFD</th>
                                <th>Transferred to</th>
                                <th>Approved by</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredData" :key="index">
                                <td>{{ item.managerName }}</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.kytDate }}</td>
                                <td>
                                    <span 
                                        :class="{
                                            'badge': true,
                                            'bg-primary': item.type === 'Transfer',
                                            'bg-success': item.type === 'Change'
                                        }"
                                    >
                                        {{ item.type }}
                                    </span>
                                </td>
                                <td>{{ item.projectName }}</td>
                                <td>
                                    {{ item.workDetails }}
                                    <button 
                                        class="btn btn-primary btn-xs ms-2"
                                        @click="viewWorkDetails(item)">
                                        (View)
                                    </button>
                                </td>
                                <td>{{ item.reason }}</td>
                                <td>{{ item.newNFD }}</td>
                                <td>{{ item.transferredTo }}</td>
                                <td>
                                    <span 
                                        :class="{
                                            'badge': true,
                                            'bg-success': item.status === 'Approved',
                                            'bg-warning': item.status === 'Pending'
                                        }"
                                    >
                                        {{ item.status }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.table th, .table td {
    vertical-align: middle;
    font-size: 0.875rem;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 500;
}

.badge {
    padding: 0.5em 0.8em;
    font-weight: 500;
    font-size: 0.75rem;
}
</style>