<script setup>
import { ref, computed, onMounted } from 'vue';

// Filter refs
const nameFilter = ref('');
const dateFilter = ref('');
const serviceFilter = ref('');

// Data refs
const competitorData = ref([]);
const summary = ref({
    totalReports: 0,
    totalProjects: 0,
    totalManpower: 0,
    averageManpower: 0
});

// Fetch data from API
async function fetchData() {
    try {
        const response = await fetch('http://localhost:3000/api/competitor-report');
        const result = await response.json();
        if (result.success) {
            competitorData.value = result.data;
            summary.value = result.summary;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

// Load data on component mount
onMounted(() => {
    fetchData();
});

// Computed filtered data
const filteredData = computed(() => {
    return competitorData.value.filter(item => {
        const matchesName = !nameFilter.value || 
            item.projectName.toLowerCase().includes(nameFilter.value.toLowerCase());
        const matchesDate = !dateFilter.value || 
            item.date.includes(dateFilter.value);
        const matchesService = !serviceFilter.value || 
            item.modeOfService.toLowerCase().includes(serviceFilter.value.toLowerCase());

        return matchesName && matchesDate && matchesService;
    });
});

function clearFilters() {
    nameFilter.value = '';
    dateFilter.value = '';
    serviceFilter.value = '';
}
</script>

<template>
    <div class="container-fluid">
        <!-- Summary Section -->
        <div class="row mb-4">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Total Reports</h6>
                        <h3>{{ summary.totalReports }}</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Total Projects</h6>
                        <h3>{{ summary.totalProjects }}</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Total Manpower</h6>
                        <h3>{{ summary.totalManpower }}</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Average Manpower</h6>
                        <h3>{{ summary.averageManpower }}</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <!-- Filters Section -->
                <div class="row mb-4">
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Name Filter</label>
                        <input type="text" class="form-control" v-model="nameFilter" placeholder="Filter by name">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Date Filter</label>
                        <input type="text" class="form-control" v-model="dateFilter" placeholder="Filter by date">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Service Filter</label>
                        <input type="text" class="form-control" v-model="serviceFilter" placeholder="Filter by service">
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button class="btn btn-secondary" @click="clearFilters">Clear</button>
                    </div>
                </div>

                <!-- Table Section -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Sl no</th>
                                <th>Date</th>
                                <th>Project name</th>
                                <th>Area</th>
                                <th>Mode of Service</th>
                                <th>Designation</th>
                                <th>Service wise manpower</th>
                                <th>Total manpower</th>
                                <th>Working hour</th>
                                <th>Minimum wages</th>
                                <th>Salary</th>
                                <th>Food</th>
                                <th>Stay</th>
                                <th>ESIC/PF</th>
                                <th>Weekly off</th>
                                <th>Agreement Duration</th>
                                <th>Special mention</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in filteredData" :key="item.srNo">
                                <td>{{ item.srNo }}</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.projectName }}</td>
                                <td>{{ item.area }}</td>
                                <td>{{ item.modeOfService }}</td>
                                <td>{{ item.designation }}</td>
                                <td>{{ item.serviceWiseManpower }}</td>
                                <td>{{ item.totalManpower }}</td>
                                <td>{{ item.workingHour }}</td>
                                <td>{{ item.minimumWages }}</td>
                                <td>{{ item.salary }}</td>
                                <td>{{ item.food }}</td>
                                <td>{{ item.stay }}</td>
                                <td>{{ item.esicPf }}</td>
                                <td>{{ item.weeklyOff }}</td>
                                <td>{{ item.agreementDuration }}</td>
                                <td>{{ item.specialMention }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table th, .table td {
    vertical-align: middle;
    font-size: 0.875rem;
    white-space: nowrap;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.card {
    margin-bottom: 1rem;
}

.card-title {
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.card h3 {
    margin-bottom: 0;
    color: #2c3e50;
}
</style>