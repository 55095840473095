<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import Multiselect from 'vue-multiselect';

const allData = ref([]);
const elementsPerPage = ref(10);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

// Search and filter states
const startDate = ref('');
const endDate = ref('');
const selectedAreas = ref([]);
const areas = ref([]); // Will populate based on allData

// Add these refs after other refs
const sortBy = ref('visits'); // Default sort by visits
const sortDirection = ref('desc'); // Default sort direction

// Add this new ref for tracking expanded rows
const expandedRows = ref(new Set());

onMounted(async () => {
    await getData();
    populateAreas();
});

// Fetch data from API
async function getData() {
    try {
        const response = await fetchGetApi('api/operation/get/site/by/area');

        if (!response?.data?.data) {
            toast.error('Failed to fetch data');
            return;
        }

        allData.value = response.data.data;

        if (allData.value.length === 0) {
            toast.warning('No data available');
            return;
        }

    } catch (error) {
        console.error('API Error:', error);
        toast.error('An error occurred while fetching data');
    }
}

// Populate areas for the multiselect based on fetched data
function populateAreas() {
    areas.value = allData.value.map(area => ({
        name: area.Area
    }));
}

// Computed property for filtered data
const filteredData = computed(() => {
    let data = allData.value;

    // Filter by search query
    if (filterQuery.value) {
        const query = filterQuery.value.toLowerCase();
        data = data.filter(item => item.Area.toLowerCase().includes(query));
    }

    // Filter by selected areas
    if (selectedAreas.value.length > 0) {
        const selectedNames = selectedAreas.value.map(area => area.name.toLowerCase());
        data = data.filter(item => selectedNames.includes(item.Area.toLowerCase()));
    }

    // Filter by date range if needed (assuming you want to filter based on visit dates)
    if (startDate.value && endDate.value) {
        const start = new Date(startDate.value);
        const end = new Date(endDate.value);

        data = data.filter(item => {
            // Check visits across all marketing managers
            const hasVisitsInRange = item.VisitsByMarketingManagers?.some(emp => 
                emp.visits?.some(visit => {
                    const visitDate = new Date(visit.NFD);
                    return visitDate >= start && visitDate <= end;
                })
            );

            // Check follow-up visits
            const hasFollowUpsInRange = item.FollowUpVisits?.some(emp => 
                emp.visits?.some(visit => {
                    const visitDate = new Date(visit.NFD);
                    return visitDate >= start && visitDate <= end;
                })
            );

            return hasVisitsInRange || hasFollowUpsInRange;
        });
    }

    return data;
});

// Update the sortedData computed property
const sortedData = computed(() => {
    let data = [...filteredData.value];

    // Define sorting functions for different columns
    const sortFunctions = {
        pincode: (a, b) => (a.AreaPinCode || '').localeCompare(b.AreaPinCode || ''),
        area: (a, b) => (a.Area || '').localeCompare(b.Area || ''),
        projects: (a, b) => (b.NoOfProjects || 0) - (a.NoOfProjects || 0),
        manpower: (a, b) => (b.TotalNoOfManpower || 0) - (a.TotalNoOfManpower || 0),
        visits: (a, b) => {
            const aCount = a.VisitsByMarketingManagers?.reduce((sum, emp) => 
                sum + (emp.visits?.length || 0), 0) || 0;
            const bCount = b.VisitsByMarketingManagers?.reduce((sum, emp) => 
                sum + (emp.visits?.length || 0), 0) || 0;
            return bCount - aCount;
        },
        services: (a, b) => ((a.ServiceRequired || []).length || 0) - ((b.ServiceRequired || []).length || 0),
        calls: (a, b) => ((a.Calls || []).length || 0) - ((b.Calls || []).length || 0),
        newVisits: (a, b) => ((a.NewProjects || []).length || 0) - ((b.NewProjects || []).length || 0),
        followUps: (a, b) => {
            const aCount = a.FollowUpVisits?.reduce((sum, emp) => 
                sum + (emp.visits?.length || 0), 0) || 0;
            const bCount = b.FollowUpVisits?.reduce((sum, emp) => 
                sum + (emp.visits?.length || 0), 0) || 0;
            return bCount - aCount;
        },
        newProjects: (a, b) => ((a.NewProjects || []).length || 0) - ((b.NewProjects || []).length || 0),

    };

    // Sort the data based on selected column
    if (sortFunctions[sortBy.value]) {
        data.sort((a, b) => {
            const result = sortFunctions[sortBy.value](a, b);
            return sortDirection.value === 'asc' ? -result : result;
        });
    }

    return data;
});

// Replace the paginatedData computed property with this updated version
const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return sortedData.value.slice(start, end);
});

// Total number of pages
const totalPages = computed(() => {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
});

// Pagination methods
function change_page(page) {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
    }
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
    }
}

function search(event) {
    event.preventDefault();
    currentPage.value = 1;
}

function formatArray(array, key1, key2 = null) {
    if (!Array.isArray(array) || array.length === 0 || array.every(item => item === null)) return '-';

    if (key2) {
        return array.map(item => {
            // For calls with empty employee name, just show the date and rating
            if (!item[key1] && item.NFD) {
                const date = formatDate(item.NFD);
                const rating = item.rating ? ` (Rating: ${Number(item.rating).toFixed(2)}%)` : '';
                return `${date}${rating}`;
            }

            const name = item[key1] || '-';
            const date = formatDate(item[key2] || item.NFD);
            const rating = item.rating ? ` (Rating: ${Number(item.rating).toFixed(2)}%)` : '';
            return `${name} - ${date}${rating}`;
        }).join(', ');
    }

    // Special handling for NewProjects
    if (array[0] && 'projectName' in array[0]) {
        return array.map(item => {
            const projectName = item.projectName || '-';
            const employeeName = item.employeeName ? ` (${item.employeeName})` : '';
            return `${projectName}${employeeName}`;
        }).join('\n');
    }

    return array.filter(item => item !== null).map(item => item[key1] || item).join(', ');
}

function formatDate(dateString) {
    if (!dateString) return '';

    // Handle date format like "11-12-2024 – 02:36 PM"
    if (dateString.includes('–')) {
        // Remove the en-dash and just use space
        return dateString.replace('–', '').trim();
    }

    // Handle standard date format
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid

    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

// Add this function to toggle row expansion
function toggleRowExpansion(index) {
    if (expandedRows.value.has(index)) {
        expandedRows.value.delete(index);
    } else {
        expandedRows.value.add(index);
    }
}

// Modify the formatVisits function to include a preview option
function formatVisits(visitsData, isExpanded = false) {
    if (!Array.isArray(visitsData) || visitsData.length === 0) return '-';

    const formatted = visitsData.map(employeeVisits => {
        const employeeName = employeeVisits.employeeName || '-';
        const formattedVisits = employeeVisits.visits.map(visit => {
            const date = formatDate(visit.NFD);
            const rating = visit.rating ? ` (Rating: ${Number(visit.rating).toFixed(2)}%)` : '';
            return `${date}${rating}`;
        });

        // If not expanded, show only first visit
        if (!isExpanded && formattedVisits.length > 1) {
            return `${employeeName}: ${formattedVisits[0]} (+${formattedVisits.length - 1} more)`;
        }
        
        return `${employeeName}:\n${formattedVisits.join('\n')}`;
    }).join('\n');

    return formatted;
}

function toggleSort(column) {
    if (sortBy.value === column) {
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
        sortBy.value = column;
        sortDirection.value = 'desc';
    }
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Area analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <!-- Area analysis, Date range, Search by area -->
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer" @submit="search">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date range:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                    <span class="mx-1">to</span>
                                    <input type="date" class="form-control" v-model="endDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-select" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    area:</label>
                                <multiselect id="area-select" v-model="selectedAreas" :options="areas" :multiple="true"
                                    :searchable="true" placeholder="Select areas" label="name" track-by="name"
                                    class="form-control">
                                </multiselect>
                            </div>
                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" type="submit">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <!-- Optional: Add a search input if needed -->
                        <!-- <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                        </div> -->

                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;">SL</th>
                                    <th scope="col" @click="toggleSort('pincode')" style="cursor: pointer;">
                                        Pincodes/Area
                                        <i v-if="sortBy === 'pincode'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('projects')" style="cursor: pointer;">
                                        No. of project
                                        <i v-if="sortBy === 'projects'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('manpower')" style="cursor: pointer;">
                                        Total no of manpower
                                        <i v-if="sortBy === 'manpower'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('services')" style="cursor: pointer;">
                                        Service required
                                        <i v-if="sortBy === 'services'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('visits')" style="cursor: pointer;">
                                        Visits by marketing managers
                                        <i v-if="sortBy === 'visits'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('calls')" style="cursor: pointer;">
                                        Calls
                                        <i v-if="sortBy === 'calls'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('newProjects')" style="cursor: pointer;">
                                        New project found visits
                                        <i v-if="sortBy === 'newProjects'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('followUps')" style="cursor: pointer;">
                                        Follow up visits
                                        <i v-if="sortBy === 'followUps'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in paginatedData" :key="item.Area">
                                    <td>{{ (currentPage - 1) * elementsPerPage + index + 1 }}</td>
                                    <td>{{ item.AreaPinCode }}<br>{{ item.Area }}</td>
                                    <td>
                                        <router-link v-if="item.NoOfProjects > 0"
                                            :to="`area/area-project-view/${item.id}`" class="text-primary me-2"
                                            title="View" target="_blank" style="cursor: pointer; font-size:14px;">
                                            {{ item.NoOfProjects }}
                                        </router-link>
                                        <span v-else>{{ item.NoOfProjects }}</span>
                                    </td>
                                    <td>{{ item.TotalNoOfManpower || 0 }}</td>
                                    <td>{{ formatArray(item.ServiceRequired) }}</td>
                                    <td class="whitespace-pre-line">
                                        {{ formatVisits(item.VisitsByMarketingManagers, expandedRows.has(index)) }}
                                        <div v-if="item.VisitsByMarketingManagers?.length > 0" 
                                             class="text-primary cursor-pointer mt-1"
                                             @click="toggleRowExpansion(index)">
                                            {{ expandedRows.has(index) ? 'Show Less' : 'Show More' }}
                                        </div>
                                    </td>
                                    <td>{{ formatArray(item.Calls, 'employeeName', 'date') }}</td>
                                    <td class="whitespace-pre-line">{{ formatArray(item.NewProjects) }}</td>
                                    <td class="whitespace-pre-line">
                                        {{ formatVisits(item.FollowUpVisits, expandedRows.has(index)) }}
                                        <div v-if="item.FollowUpVisits?.length > 0" 
                                             class="text-primary cursor-pointer mt-1"
                                             @click="toggleRowExpansion(index)">
                                            {{ expandedRows.has(index) ? 'Show Less' : 'Show More' }}
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="paginatedData.length === 0">
                                    <td colspan="10" class="text-center">No data available</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <!-- Pagination -->
                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" :class="{ disabled: currentPage === 1 }" @click="previousPage">
                        <a class="page-link" href="#">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in totalPages" :key="i" :class="{ active: i === currentPage }"
                        @click="change_page(i)">
                        <a class="page-link" href="#">{{ i }}</a>
                    </li>
                    <li class="page-item" :class="{ disabled: currentPage === totalPages }" @click="nextPage">
                        <a class="page-link" href="#">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
.whitespace-pre-line {
    white-space: pre-line;
}

table td {
    vertical-align: top;
    padding: 8px;
}

/* Add these to your existing styles */
th {
    position: relative;
    padding-right: 20px !important;
}

th i {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8em;
}

th:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Add to your existing styles */
.text-primary {
    color: #7366ff;
    font-size: 0.85em;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    text-decoration: underline;
}

/* Update the table cell styles */
table td {
    vertical-align: top;
    padding: 8px;
    max-width: 250px; /* Limit the width of cells */
    overflow: hidden;
}

/* Add transition for smooth expansion */
td {
    transition: all 0.3s ease;
}
</style>
