import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "site-form-container" }
const _hoisted_2 = { class: "card mb-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card mb-4" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "card mb-4" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "card mb-4" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "card mb-4" }
const _hoisted_11 = { class: "card-body" }
const _hoisted_12 = { class: "card mb-4" }
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "text-end" }
const _hoisted_15 = ["disabled"]

import { ref, reactive, watch } from 'vue';
import { useToast } from 'vue-toast-notification';
import BasicSiteInfo from './basic-site-info.vue';
import WorkforceDetails from './work-force-details.vue';
import SopSection from './sop-section.vue';
import WorkOrderSection from './work-order-section.vue';
import ClientRequirements from './client-requirements.vue';
import BillingDetails from './billing-details.vue';
import { useRouter } from 'vue-router';
import { fetchPostApi } from '@/util/api';


export default /*@__PURE__*/_defineComponent({
  __name: 'site-creation-form',
  setup(__props) {

const toast = useToast();
const isSubmitting = ref(false);
const router = useRouter();

const formData = reactive({
    basicInfo: {
        siteName: '',
        quotation: {
            type: '',
            file: null as File | null
        },
        workOrder: {
            type: '',
            file: null as File | null
        },
        client: {
            name: '',
            designation: '',
            phone: '',
            altPhone: '',
            email: ''
        },
        otherContact: {
            name: '',
            designation: '',
            phone: '',
            altPhone: '',
            email: ''
        },
        officeAddress: '',
        siteAddress: ''
    },
    workforce: {
        totalManpower: 0,
        workingPlace: '',
        designations: [],
        subDesignationDetails: {}
    },
    sops: [],
    workOrders: [],
    clientRequirements: {
        specificRequirements: '',
        serviceStartDate: {
            male: '',
            female: ''
        }
    },
    billing: {
        type: '',
        gstNumber: ''
    }
});

watch(() => formData, (newVal) => {
    console.log('Form Data Updated:', newVal);
}, { deep: true });

const validateForm = () => {
    if (!formData.basicInfo.siteName) {
        toast.error('Site name is required');
        return false;
    }
    
    if (!formData.basicInfo.officeAddress) {
        toast.error('Office address is required');
        return false;
    }
    
    if (!formData.basicInfo.siteAddress) {
        toast.error('Site address is required');
        return false;
    }

    if (!formData.workforce.totalManpower) {
        toast.error('Total manpower is required');
        return false;
    }

    if (!formData.billing.type) {
        toast.error('Billing type is required');
        return false;
    }

    return true;
};

const handleSubmit = async () => {
    try {
        if (!validateForm()) {
            return;
        }

        isSubmitting.value = true;
        const submitFormData = new FormData();

        // Basic Site Information
        submitFormData.append('siteName', formData.basicInfo.siteName);
        submitFormData.append('officeAddress', formData.basicInfo.officeAddress);
        submitFormData.append('siteAddress', formData.basicInfo.siteAddress);
        submitFormData.append('quotationType', formData.basicInfo.quotation.type);
        submitFormData.append('workOrderType', formData.basicInfo.workOrder.type);

        // Workforce Details
        const workforceData = {
            totalManpower: Number(formData.workforce.totalManpower),
            workingPlace: formData.workforce.workingPlace,
            designations: formData.workforce.designations,
            subDesignationDetails: Object.fromEntries(
                Object.entries(formData.workforce.subDesignationDetails).map(([key, value]) => [
                    key,
                    {
                        manpower: value.manpower,
                        weeklyOff: value.weeklyOff,
                        workingHours: value.workingHours,
                        esiEpf: value.esiEpf,
                        food: value.food,
                        accommodation: value.accommodation,
                        deadline: value.deadline,
                        inHandSalary: value.inHandSalary,
                        quotedRate: value.quotedRate,
                        serviceStartDate: value.serviceStartDate
                    }
                ])
            )
        };
        submitFormData.append('workforce', JSON.stringify(workforceData));

        // SOPs
        const sopsData = formData.sops.map(sop => ({
            question: sop.question,
            answer: sop.answer
        }));
        submitFormData.append('sops', JSON.stringify(sopsData));

        // Client Requirements
        const clientRequirementsData = {
            specificRequirements: formData.clientRequirements.specificRequirements,
            serviceStartDate: {
                male: formData.clientRequirements.serviceStartDate.male,
                female: formData.clientRequirements.serviceStartDate.female
            }
        };
        submitFormData.append('clientRequirements', JSON.stringify(clientRequirementsData));

        // Billing Details
        const billingData = {
            type: formData.billing.type,
            gstNumber: formData.billing.gstNumber
        };
        submitFormData.append('billing', JSON.stringify(billingData));

        // Client Information
        const clientData = {
            name: formData.basicInfo.client.name,
            designation: formData.basicInfo.client.designation,
            phone: formData.basicInfo.client.phone,
            email: formData.basicInfo.client.email,
            altPhone: formData.basicInfo.client.altPhone
        };
        submitFormData.append('client', JSON.stringify(clientData));

        // Contact Person Information (if provided)
        if (formData.basicInfo.otherContact.name) {
            const contactPersonData = {
                name: formData.basicInfo.otherContact.name,
                designation: formData.basicInfo.otherContact.designation,
                phone: formData.basicInfo.otherContact.phone,
                email: formData.basicInfo.otherContact.email,
                altPhone: formData.basicInfo.otherContact.altPhone
            };
            submitFormData.append('contactPerson', JSON.stringify(contactPersonData));
        }

        // File Uploads
        if (formData.basicInfo.quotation.file) {
            submitFormData.append('quotation', formData.basicInfo.quotation.file);
        }

        if (formData.basicInfo.workOrder.file) {
            submitFormData.append('workOrder', formData.basicInfo.workOrder.file);
        }

        // Additional Work Orders
        if (formData.workOrders.length > 0) {
            formData.workOrders.forEach(file => {
                submitFormData.append('additionalWorkOrders', file);
            });
        }

        // Make API call
        const response = await fetchPostApi(
            `api/temp/add/site`, 
            submitFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.data.success) {
            toast.success('Site created successfully');
            router.push('/site-creation/list');
        } else {
            throw new Error(response.data.message || 'Error creating site');
        }
    } catch (error) {
        console.error('Error creating site:', error);
        toast.error(error.message || 'Error creating site');
    } finally {
        isSubmitting.value = false;
    }
};

const handleFileUpload = (type: string, file: File) => {
    if (type === 'quotation') {
        formData.basicInfo.quotation.file = file;
    } else if (type === 'workOrder') {
        formData.basicInfo.workOrder.file = file;
    }
};
const handleDesignationChange = (designations: string[]) => {
    formData.workforce.designations = designations as never[];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "mb-4" }, "Create New Site", -1)),
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleSubmit, ["prevent"]),
      class: "needs-validation"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Basic Site Information")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BasicSiteInfo, {
            formData: formData.basicInfo,
            "onUpdate:formData": _cache[0] || (_cache[0] = ($event: any) => ((formData.basicInfo) = $event)),
            onFileUpload: handleFileUpload
          }, null, 8, ["formData"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Workforce Details")
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(WorkforceDetails, {
            formData: formData.workforce,
            "onUpdate:formData": _cache[1] || (_cache[1] = ($event: any) => ((formData.workforce) = $event)),
            onDesignationChange: handleDesignationChange
          }, null, 8, ["formData"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Standard Operating Procedures")
        ], -1)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(SopSection, {
            sops: formData.sops,
            "onUpdate:sops": _cache[2] || (_cache[2] = ($event: any) => ((formData.sops) = $event))
          }, null, 8, ["sops"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Work Order Documents")
        ], -1)),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(WorkOrderSection, {
            workOrders: formData.workOrders,
            "onUpdate:workOrders": _cache[3] || (_cache[3] = ($event: any) => ((formData.workOrders) = $event))
          }, null, 8, ["workOrders"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Client Requirements")
        ], -1)),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(ClientRequirements, {
            requirements: formData.clientRequirements,
            "onUpdate:requirements": _cache[4] || (_cache[4] = ($event: any) => ((formData.clientRequirements) = $event))
          }, null, 8, ["requirements"])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Billing Details")
        ], -1)),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(BillingDetails, {
            billing: formData.billing,
            "onUpdate:billing": _cache[5] || (_cache[5] = ($event: any) => ((formData.billing) = $event))
          }, null, 8, ["billing"])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn-primary",
          disabled: isSubmitting.value
        }, _toDisplayString(isSubmitting.value ? 'Creating Site...' : 'Create Site'), 9, _hoisted_15)
      ])
    ], 32)
  ]))
}
}

})