import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "workforce-details" }
const _hoisted_2 = { class: "row mb-4" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "designation-selection mb-4" }
const _hoisted_6 = { class: "designation-checkboxes" }
const _hoisted_7 = { class: "form-check mb-2" }
const _hoisted_8 = { class: "form-check mb-2" }
const _hoisted_9 = { class: "form-check mb-2" }
const _hoisted_10 = {
  key: 0,
  class: "sub-designation-section mb-4"
}
const _hoisted_11 = {
  key: 1,
  class: "sub-designation-section mb-4"
}
const _hoisted_12 = {
  key: 2,
  class: "sub-designation-section mb-4"
}
const _hoisted_13 = {
  key: 0,
  class: "custom-position mt-3"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-md-6" }

import { reactive, watch, ref } from 'vue';
import SubDesignationDetails from './sub-designation.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'work-force-details',
  props: {
    formData: {}
  },
  emits: ['update:formData'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const localFormData = reactive({
    totalManpower: props.formData.totalManpower || 0,
    workingPlace: props.formData.workingPlace || '',
    designations: [...(props.formData.designations || [])],
    subDesignationDetails: { ...props.formData.subDesignationDetails }
});

const selectedDesignations = reactive({
    housekeeping: false,
    security: false,
    facilityManagement: false
});

const housekeepingDesignations = [
    'Housekeeping Supervisor',
    'Head Janitors',
    'Housekeeping Janitors'
];

const securityDesignations = [
    'Security Supervisor',
    'Head Guards',
    'Security Guards'
];

const facilityDesignations = [
    'Facility Manager',
    'Gardener',
    'Assistant Gardener',
    'Plumber',
    'Assistant Plumber',
    'Electrician',
    'Assistant Electrician',
    'Lift Man',
    'Parking Boy',
    'Parking Driver'
];

const showCustomPosition = ref(false);
const customPositionName = ref('');

const addCustomPosition = () => {
    if (customPositionName.value.trim()) {
        localFormData.subDesignationDetails[customPositionName.value] = {
            manpower: { male: 0, female: 0 },
            weeklyOff: { male: false, female: false },
            workingHours: { male: 0, female: 0 },
            esiEpf: { male: false, female: false },
            food: { male: false, female: false },
            accommodation: { male: false, female: false },
            deadline: { male: '', female: '' },
            inHandSalary: { male: 0, female: 0 },
            quotedRate: { male: 0, female: 0 },
            serviceStartDate: { male: '', female: '' }
        };
        customPositionName.value = '';
    }
};

const initializeSubDesignationDetails = (designations: string[]) => {
    const details: Record<string, any> = {};
    designations.forEach(designation => {
        if (!localFormData.subDesignationDetails[designation]) {
            details[designation] = {
                manpower: { male: 0, female: 0 },
                weeklyOff: { male: false, female: false },
                workingHours: { male: 0, female: 0 },
                esiEpf: { male: false, female: false },
                food: { male: false, female: false },
                accommodation: { male: false, female: false },
                deadline: { male: '', female: '' },
                inHandSalary: { male: 0, female: 0 },
                quotedRate: { male: 0, female: 0 },
                serviceStartDate: { male: '', female: '' }
            };
        } else {
            details[designation] = localFormData.subDesignationDetails[designation];
        }
    });
    return details;
};

watch(selectedDesignations, (newVal) => {
    const designations: string[] = [];
    if (newVal.housekeeping) designations.push(...housekeepingDesignations);
    if (newVal.security) designations.push(...securityDesignations);
    if (newVal.facilityManagement) designations.push(...facilityDesignations);
    
    localFormData.designations = designations;
    // Initialize details for new designations
    const newDetails = initializeSubDesignationDetails(designations);
    localFormData.subDesignationDetails = newDetails;
}, { deep: true });

watch(localFormData, (newVal) => {
    emit('update:formData', { ...newVal });
}, { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Total Manpower*", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "number",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localFormData.totalManpower) = $event)),
          required: "",
          min: "1"
        }, null, 512), [
          [_vModelText, localFormData.totalManpower]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label" }, "Working Place*", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localFormData.workingPlace) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, localFormData.workingPlace]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, "Designations*", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            class: "form-check-input",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedDesignations.housekeeping) = $event)),
            id: "housekeeping"
          }, null, 512), [
            [_vModelCheckbox, selectedDesignations.housekeeping]
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            class: "form-check-label",
            for: "housekeeping"
          }, "Housekeeping", -1))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            class: "form-check-input",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedDesignations.security) = $event)),
            id: "security"
          }, null, 512), [
            [_vModelCheckbox, selectedDesignations.security]
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("label", {
            class: "form-check-label",
            for: "security"
          }, "Security", -1))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            class: "form-check-input",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedDesignations.facilityManagement) = $event)),
            id: "facilityManagement"
          }, null, 512), [
            [_vModelCheckbox, selectedDesignations.facilityManagement]
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            class: "form-check-label",
            for: "facilityManagement"
          }, "Facility Management", -1))
        ])
      ])
    ]),
    (selectedDesignations.housekeeping)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _cache[12] || (_cache[12] = _createElementVNode("h6", null, "Housekeeping Positions", -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(housekeepingDesignations, (designation) => {
            return _createVNode(SubDesignationDetails, {
              key: designation,
              designation: designation,
              details: localFormData.subDesignationDetails[designation],
              "onUpdate:details": ($event: any) => ((localFormData.subDesignationDetails[designation]) = $event)
            }, null, 8, ["designation", "details", "onUpdate:details"])
          }), 64))
        ]))
      : _createCommentVNode("", true),
    (selectedDesignations.security)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _cache[13] || (_cache[13] = _createElementVNode("h6", null, "Security Positions", -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(securityDesignations, (designation) => {
            return _createVNode(SubDesignationDetails, {
              key: designation,
              designation: designation,
              details: localFormData.subDesignationDetails[designation],
              "onUpdate:details": ($event: any) => ((localFormData.subDesignationDetails[designation]) = $event)
            }, null, 8, ["designation", "details", "onUpdate:details"])
          }), 64))
        ]))
      : _createCommentVNode("", true),
    (selectedDesignations.facilityManagement)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _cache[16] || (_cache[16] = _createElementVNode("h6", null, "Facility Management Positions", -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(facilityDesignations, (designation) => {
            return _createVNode(SubDesignationDetails, {
              key: designation,
              designation: designation,
              details: localFormData.subDesignationDetails[designation],
              "onUpdate:details": ($event: any) => ((localFormData.subDesignationDetails[designation]) = $event)
            }, null, 8, ["designation", "details", "onUpdate:details"])
          }), 64)),
          (showCustomPosition.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Custom Position Name*", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((customPositionName).value = $event)),
                      placeholder: "Enter position name",
                      required: ""
                    }, null, 512), [
                      [_vModelText, customPositionName.value]
                    ]),
                    _cache[15] || (_cache[15] = _createElementVNode("small", { class: "text-muted" }, "Note: Requires admin approval", -1))
                  ]),
                  _createElementVNode("div", { class: "col-md-6" }, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-primary mt-4",
                      onClick: addCustomPosition
                    }, " Add Position ")
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})