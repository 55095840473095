<!-- src/components/site-management/sections/WorkforceDetails.vue -->
<template>
    <div class="workforce-details">
        <!-- Common Inputs -->
        <div class="row mb-4">
            <div class="col-md-6">
                <label class="form-label">Total Manpower*</label>
                <input type="number" class="form-control" v-model="localFormData.totalManpower" required min="1" />
            </div>
            <div class="col-md-6">
                <label class="form-label">Working Place*</label>
                <input type="text" class="form-control" v-model="localFormData.workingPlace" required />
            </div>
        </div>

        <!-- Designation Selection -->
        <div class="designation-selection mb-4">
            <label class="form-label">Designations*</label>
            <div class="designation-checkboxes">
                <div class="form-check mb-2">
                    <input type="checkbox" class="form-check-input" v-model="selectedDesignations.housekeeping"
                        id="housekeeping" />
                    <label class="form-check-label" for="housekeeping">Housekeeping</label>
                </div>
                <div class="form-check mb-2">
                    <input type="checkbox" class="form-check-input" v-model="selectedDesignations.security"
                        id="security" />
                    <label class="form-check-label" for="security">Security</label>
                </div>
                <div class="form-check mb-2">
                    <input type="checkbox" class="form-check-input" v-model="selectedDesignations.facilityManagement"
                        id="facilityManagement" />
                    <label class="form-check-label" for="facilityManagement">Facility Management</label>
                </div>
            </div>
        </div>

        <!-- Sub-Designation Details -->
        <!-- Housekeeping Section -->
        <div v-if="selectedDesignations.housekeeping" class="sub-designation-section mb-4">
            <h6>Housekeeping Positions</h6>
            <SubDesignationDetails v-for="designation in housekeepingDesignations" :key="designation"
                :designation="designation" v-model:details="localFormData.subDesignationDetails[designation]" />
        </div>

        <!-- Security Section -->
        <div v-if="selectedDesignations.security" class="sub-designation-section mb-4">
            <h6>Security Positions</h6>
            <SubDesignationDetails v-for="designation in securityDesignations" :key="designation"
                :designation="designation" v-model:details="localFormData.subDesignationDetails[designation]" />
        </div>

        <!-- Facility Management Section -->
        <div v-if="selectedDesignations.facilityManagement" class="sub-designation-section mb-4">
            <h6>Facility Management Positions</h6>
            <SubDesignationDetails v-for="designation in facilityDesignations" :key="designation"
                :designation="designation" v-model:details="localFormData.subDesignationDetails[designation]" />

            <!-- Custom Position Input -->
            <div v-if="showCustomPosition" class="custom-position mt-3">
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">Custom Position Name*</label>
                        <input type="text" class="form-control" v-model="customPositionName"
                            placeholder="Enter position name" required />
                        <small class="text-muted">Note: Requires admin approval</small>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="btn btn-primary mt-4" @click="addCustomPosition">
                            Add Position
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch, ref } from 'vue';
import SubDesignationDetails from './sub-designation.vue';

const props = defineProps<{
    formData: {
        totalManpower: number;
        workingPlace: string;
        designations: string[];
        subDesignationDetails: Record<string, any>;
    };
}>();

const emit = defineEmits(['update:formData']);

const localFormData = reactive({
    totalManpower: props.formData.totalManpower || 0,
    workingPlace: props.formData.workingPlace || '',
    designations: [...(props.formData.designations || [])],
    subDesignationDetails: { ...props.formData.subDesignationDetails }
});

const selectedDesignations = reactive({
    housekeeping: false,
    security: false,
    facilityManagement: false
});

const housekeepingDesignations = [
    'Housekeeping Supervisor',
    'Head Janitors',
    'Housekeeping Janitors'
];

const securityDesignations = [
    'Security Supervisor',
    'Head Guards',
    'Security Guards'
];

const facilityDesignations = [
    'Facility Manager',
    'Gardener',
    'Assistant Gardener',
    'Plumber',
    'Assistant Plumber',
    'Electrician',
    'Assistant Electrician',
    'Lift Man',
    'Parking Boy',
    'Parking Driver'
];

const showCustomPosition = ref(false);
const customPositionName = ref('');

const addCustomPosition = () => {
    if (customPositionName.value.trim()) {
        localFormData.subDesignationDetails[customPositionName.value] = {
            manpower: { male: 0, female: 0 },
            weeklyOff: { male: false, female: false },
            workingHours: { male: 0, female: 0 },
            esiEpf: { male: false, female: false },
            food: { male: false, female: false },
            accommodation: { male: false, female: false },
            deadline: { male: '', female: '' },
            inHandSalary: { male: 0, female: 0 },
            quotedRate: { male: 0, female: 0 },
            serviceStartDate: { male: '', female: '' }
        };
        customPositionName.value = '';
    }
};

const initializeSubDesignationDetails = (designations: string[]) => {
    const details: Record<string, any> = {};
    designations.forEach(designation => {
        if (!localFormData.subDesignationDetails[designation]) {
            details[designation] = {
                manpower: { male: 0, female: 0 },
                weeklyOff: { male: false, female: false },
                workingHours: { male: 0, female: 0 },
                esiEpf: { male: false, female: false },
                food: { male: false, female: false },
                accommodation: { male: false, female: false },
                deadline: { male: '', female: '' },
                inHandSalary: { male: 0, female: 0 },
                quotedRate: { male: 0, female: 0 },
                serviceStartDate: { male: '', female: '' }
            };
        } else {
            details[designation] = localFormData.subDesignationDetails[designation];
        }
    });
    return details;
};

watch(selectedDesignations, (newVal) => {
    const designations: string[] = [];
    if (newVal.housekeeping) designations.push(...housekeepingDesignations);
    if (newVal.security) designations.push(...securityDesignations);
    if (newVal.facilityManagement) designations.push(...facilityDesignations);
    
    localFormData.designations = designations;
    // Initialize details for new designations
    const newDetails = initializeSubDesignationDetails(designations);
    localFormData.subDesignationDetails = newDetails;
}, { deep: true });

watch(localFormData, (newVal) => {
    emit('update:formData', { ...newVal });
}, { deep: true });
</script>

<style scoped>
.designation-checkboxes {
    margin-bottom: 1rem;
}

.sub-designation-section {
    border: 1px solid #dee2e6;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.custom-position {
    border-top: 1px solid #dee2e6;
    padding-top: 1rem;
}
</style>